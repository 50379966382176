import full_logo_trans_no_buffer from "./FullLogo_Transparent_NoBuffer.png"
import grayscale_trans_no_buffer from  "./Grayscale_Transparent_NoBuffer.png"; 
import grayscale_trans from  "./Grayscale_Transparent.png";
import grayscale from "./Grayscale.png"; 
import icon_only_no_buffer from "./IconOnly_NoBuffer.png"; 
import icon_only_trans from "./IconOnly_Transparent.png"; 
import icon_only from "./IconOnly.png"; 
import icon_only_trans_no_buffer from "./IconOnly_Transparent_NoBuffer.png"; 
import print_transparent_svg from "./Print_Transparent.svg"; 
import print_svg from "./Print.svg"; 
import text_only_no_buffer from "./TextOnly_NoBuffer.png"; 
import text_only from "./TextOnly.png"; 

const logos = {
    full_logo_trans_no_buffer,
    grayscale_trans_no_buffer,
    grayscale_trans,
    grayscale,
    icon_only_no_buffer,
    icon_only_trans,
    icon_only,
    icon_only_trans_no_buffer,
    print_transparent_svg,
    print_svg,
    text_only_no_buffer,
    text_only
}

export default logos; 