import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export default function TabsContainer() {
  return (
    <div className="tabsContainer mt-0">
      <Tabs
        defaultActiveKey="History"
        id="uncontrolled-tab-example"
        className="mb-3 Tabs"
        transition={true}
        fill
      >
        <Tab eventKey="History" title="History">
          <p className="mb-2">
            Durmade was founded <b>in 2024</b> by a young designer and enthusiasts
            in Nepal who were passionate about creating high-quality, affordable clothing. 
            He started out with a small line of clothing items, including varieties t-shirts, and trousers, 
            that quickly gained popularity among fashion enthusiasts.
          </p>
        
        </Tab>
        <Tab eventKey="Mission" title="Mission">
          <p className="mb-2">
            Our mission is to combine beautiful design with high-quality
            craftsmanship, while also minimizing our impact on the environment.
            To achieve this, we embrace the following core values:
          </p>
          <p className="mb-2">
            <b>Innovation: </b>
            We are constantly pushing the boundaries of design and
            manufacturing, using technology and creativity to create clothing
            that is both functional and beautiful.
          </p>
          <p className="mb-2">
            <b>Affordability: </b> We believe that high-quality, stylish
            clothing should be accessible to everyone. That's why we strive to
            keep our prices affordable, without compromising on quality or
            design.
          </p>
          <p className="mb-0">
            <b>Customer Focus: </b>
            We prioritize the needs and preferences of our customers, and strive
            to create clothing that is tailored to their unique style and
            needs.
          </p>
        </Tab>
        <Tab eventKey="Design" title="Design">
          <p className="mb-2">
            At our company, we believe that great design should be both
            beautiful and functional. Our approach to clothing design is
            centered around the following principles:
          </p>
          <p className="mb-2">
            <b>Simplicity: </b>We believe that clothing should be simple and
            uncluttered, with clean lines and minimal ornamentation. We strive
            to create clothing that is both timeless and contemporary, so that
            it can fit seamlessly into any wardrobe.
          </p>
          <p className="mb-2">
            <b>Functionality: </b> We design clothing with the wearer in mind,
            prioritizing functionality and comfort. We believe that clothing
            should not only look great, but also serve a purpose and make
            people's lives easier and more comfortable.
          </p>
          <p className="mb-0">
            <b>Customization: </b>
            We understand that every person's taste and style is unique, and we
            offer customization options to allow our customers to create
            clothing that truly reflects their individual preferences and
            needs.
          </p>
        </Tab>
      </Tabs>
    </div>
  );
}
