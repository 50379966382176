import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../apis/config';
import { showToast } from '../../store/slices/toastSlice';
import { getCart } from '../../functions/cart';
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router";
const GoogleSignInButton = ({closeModal, saveUserData}) => {
  const dispatch  = useDispatch();
    const navigate = useNavigate();
    const handleSuccess = async (response) => {
        const { credential } = response;
       const result =  await axiosInstance.post('/google-sign-in', { gToken: credential });
        
        localStorage.setItem('userToken', result.data.token);
        saveUserData();
        dispatch(showToast('Login successfully!'));
        getCart(result.data.token);
        closeModal();
        const { role } = jwtDecode(result.data.token);
        if (role === 'admin') {
          navigate('/dashboard');
        }
        
      };
      const handleFailure = (error) => {
        console.error('Google sign-in error:', error);
        console.error('Error details:', {
          error: error.error,
          details: error.details,
          type: error.type,
          ...error
        });
        dispatch(showToast("Error occurred during Google sign-in. Please try again."));
      };

  return (
    <GoogleOAuthProvider 
  clientId= {process.env.REACT_APP_GOOGLE_CLIENT_ID}
  onScriptLoadError={() => console.log('Failed to load Google script')}
  onScriptLoadSuccess={() => console.log('Google script loaded successfully')}
>
  <GoogleLogin
    onSuccess={handleSuccess}
    onError={handleFailure}
    useOneTap
    cookiePolicy={'single_host_origin'}
  />
</GoogleOAuthProvider>
  );
};

export default GoogleSignInButton;
