import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// component
import axiosInstance from "./../../apis/config";

// style
import styles from "./../../pages/home/home.module.css";

// Helper function to shuffle an array
const shuffleArray = (array) => {
  let shuffledArray = array.slice(); // Create a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
  }
  return shuffledArray;
};
const handleImageError = (e) => {
  e.target.src = "placeholder.jpg"; // Fallback image when loading the brand image fails
};
const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/categories")
      .then((response) => {
        const shuffledCategories = shuffleArray(response.data.data); // Shuffle categories
        setCategories(shuffledCategories.slice(0, 3)); // Set only the first 3 random categories
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="container-fluid px-md-5">
      <div className={`row ${styles.gapColumn} ${styles.category}`}>
        <div className={`col-12 d-flex flex-wrap ${styles.gapColumn}`}>
          {categories &&
            categories.map((category) => (
              <div key={category._id} className={`col-md-4 col-sm-6 col-12 ${styles.categoryItemWrapper}`}>
                <div className={styles.categoryItem}>
                  <img
                    src={`${category.image}`}
                    alt={category.name}
                    onError={handleImageError}
                  />
                  <div className={styles.overlay}>
                    <p>{category.products_id.length} products</p>
                    <Link
                      to={`shop?page=1&brand=all&category=${category._id}&sort=0&price=0`}
                      className={styles.categoryLink}
                    >
                      <h2>{category.name}</h2>
                    </Link>
                    <Link
                      to={`shop?page=1&brand=all&category=${category._id}&sort=0&price=0`}
                      className={styles.categoryLink}
                    >
                      <h6>
                        Go Shopping{" "}
                        <span className="ms-2">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </span>{" "}
                      </h6>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
