import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { LeftArrow, RightArrow } from "./customSliderArrows";
import Spinner from "./spinner";
import { useNavigate } from "react-router-dom";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    partialVisibilityGutter: 15
  }
};

const Brands = () => {
  const [isDragged, setIsDragged] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const brands = useSelector((state) => state.brands.brands);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDrag = () => {
    if (!isMouseDown) {
      if (isDragged) setIsDragged(false);
      return;
    }
    setIsDragged(true);
  };

  const brandItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: windowWidth < 768 ? '150px' : '250px',
    margin: '0 auto'
  };

  const imageContainerStyle = {
    width: '100%',
    height: windowWidth < 768 ? '120px' : '180px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px'
  };

  const responsiveImageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  };

  const brandNameStyle = {
    fontSize: windowWidth < 768 ? '12px' : '14px',
    textAlign: 'center',
    marginTop: '5px',
    fontWeight: 'bold'
  };

  if (brands.length === 0) {
    return <Spinner />;
  }

  return (
    <section className="brands-slider py-3 ps-2 border border-start-0 border-end-0">
      <div
        className="py-2"
        onMouseLeave={() => setIsMouseDown(false)}
        onMouseUp={() => setIsMouseDown(false)}
      >
        <Carousel
          responsive={responsive}
          containerClass="multi-carousel"
          draggable
          swipeable
          partialVisible
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          customRightArrow={<RightArrow />}
          customLeftArrow={<LeftArrow />}
        >
          {brands.map((brand) => (
            <div
              key={brand._id}
              className="pe-2 py-2"
              onMouseDown={() => setIsMouseDown(true)}
              onMouseMove={handleDrag}
              style={brandItemStyle}
              onClick={()=>  navigate(`/shop?page=1&brand=${brand._id}&category=all&sort=0&price=0`)}
            >
              <div style={imageContainerStyle}>
                <img
                  style={responsiveImageStyle}
                  src={brand.image || "/images/placeholder.jpg"}
                  alt={brand.name}
                  className="d-block img-fluid"
                />
              </div>
              <p style={brandNameStyle}>
                {brand.name}
              </p>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Brands;