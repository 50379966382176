import OtpInput from "react-otp-input";
import axiosInstance from "../../apis/config";
import { showToast } from "../../store/slices/toastSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

// Style
import styles from "./login-register.module.css";

function VerifyEmailPage({ handleOTPSuccess }) {
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const handleChange = (code) => setCode(code);
  const email = localStorage.getItem("userEmail");
  const hiddenEmail = email.replace(/.{3}(?=@)/, "***");

  const handleSubmit = async () => {
    axiosInstance
      .post("/verify-email", { email, code })
      .then((response) => {
        dispatch(showToast(response.data));
        setTimeout(() => {
          handleOTPSuccess();
        }, 2000);
      })
      .catch((error) => {
        dispatch(showToast("Verification failed. Please try again."));
      });
  };

  return (
    <div className="flex flex-col items-center justify-center appear">
      <h1 className="mb-4 title">Enter OTP</h1>
      <OtpInput
        value={code}
        onChange={handleChange}
        numInputs={6}
        renderSeparator={<span style={{ width: "8px" }}></span>}
        renderInput={(props) => <input {...props} className="input" />}
        shouldAutoFocus={true}
        inputStyle={{
          border: "1px solid transparent",
          borderRadius: "8px",
          width: "54px",
          height: "54px",
          fontSize: "18px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          backgroundColor: "#D3D3D3",
          margin: "4px",
        }}
        focusStyle={{
          border: "1px solid #118AB2",
          outline: "none",
        }}
      />
      <h2 className={`${styles.smallText}`}>
        {/* Adjusted class for smaller font size and centered alignment */}
        Please enter the OTP sent to your email {hiddenEmail}
      </h2>
      <button
        onClick={handleSubmit}
        className={`btn-bg-dark text-white ${styles.button}`} // Ensure text is white for contrast
      >
        Verify your email
      </button>
    </div>
  );
}

export default VerifyEmailPage;
