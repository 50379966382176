import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// APIs and utilities
import axiosInstance from "../../apis/config";
import { nep_cities } from "../../apis/nepali_cities";
import { showToast } from "../../store/slices/toastSlice";

// Styles
import styles from "../../pages/account/account.module.css";

// Helper functions
const getProvinceNames = (cities) => Object.keys(cities);
const getDistrictsByProvince = (province) => province && nep_cities[province] ? Object.keys(nep_cities[province]) : [];
const getMunicipalities = (province, district) => province && district && nep_cities[province] && nep_cities[province][district]
  ? Object.values(nep_cities[province][district]).flat()
  : [];

const AccountInfo = ({ user, token, setUser }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showBtnSpinner, SetShowBtnSpinner] = useState(false);
  
  const [updateUser, setUpdateUser] = useState({
    id: id,
    fullName: user?.fullName,
    email: user?.email,
    phone: user?.phone,
    address: {
      district: user?.address?.district,
      street: user?.address?.street,
      additionalInfo: user?.address?.additionalInfo,
      province: user?.address?.province,
      municipality: user?.address?.municipality,
      postalCode: user?.address?.postalCode,
    },
  });

  const [selectedProvince, setSelectedProvince] = useState(user?.address?.province || "");
  const [selectedDistrict, setSelectedDistrict] = useState(user?.address?.district || "");
  const [selectedMunicipality, setSelectedMunicipality] = useState(user?.address?.municipality || "");
  const [municipalities, setMunicipalities] = useState([]);
  const [districts, setDistricts] = useState([]);

  // Update districts and municipalities based on the selected province
  useEffect(() => {
    setDistricts(getDistrictsByProvince(selectedProvince));
    if (selectedDistrict) {
      setMunicipalities(getMunicipalities(selectedProvince, selectedDistrict));
    }
  }, [selectedProvince]);

  // Update municipalities based on the selected district
  useEffect(() => {
    if (selectedProvince && selectedDistrict) {
      setMunicipalities(getMunicipalities(selectedProvince, selectedDistrict));
    } else {
      setMunicipalities([]);
    }
  }, [selectedDistrict]);

  const handleProvinceChange = (event, setFieldValue) => {
    const province = event.target.value;
    setFieldValue('address.province', province);
    setFieldValue('address.district', '');
    setFieldValue('address.municipality', '');
    setSelectedProvince(province);
    setSelectedDistrict('');
    setSelectedMunicipality('');
  };
  
  const handleDistrictChange = (event, setFieldValue) => {
    const district = event.target.value;
    setFieldValue('address.district', district);
    setFieldValue('address.municipality', '');
    setSelectedDistrict(district);
    setSelectedMunicipality('');
  };
  
  const handleMunicipalityChange = (event, setFieldValue) => {
    const municipality = event.target.value;
    setFieldValue('address.municipality', municipality);
    setSelectedMunicipality(municipality);
  };

  const provinceNames = getProvinceNames(nep_cities);

  // Submit updated user info
  const updateUserSubmit = (values) => {
    SetShowBtnSpinner(true);
    axiosInstance
      .patch("/users", values, {
        params: { id: id },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((res) => {
        dispatch(showToast("Account Updated successfully!"));
        SetShowBtnSpinner(false);
        setUser(values);
      })
      .catch((err) => {
        dispatch(showToast("Unable to update, please try again."));
        SetShowBtnSpinner(false);
      });
  };

  return (
    <div>
      <h2 className={`${styles["text-2xl"]} ${styles.subTitle}`}>
        Account Information
      </h2>
      <Formik
        initialValues={{
          id: id,
          fullName: user?.fullName,
          email: user?.email,
          phone: user?.phone,
          address: {
            province: user?.address?.province || "",
            district: user?.address?.district || "",
            municipality: user?.address?.municipality || "",
            street: user?.address?.street || "",
            additionalInfo: user?.address?.additionalInfo || "",
            postalCode: user?.address?.postalCode || "",
          },
        }}
        validationSchema={Yup.object({
          fullName: Yup.string()
            .required("Full name is required")
            .matches(/^[a-zA-Z ]+$/, "Full name shouldn't have numbers")
            .min(3, "Full name must be at least 3 characters")
            .max(50, "Full name must be less than 50 characters"),
          email: Yup.string()
            .required("Email is required")
            .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i, "Email must be a valid email address")
            .test("email-username-length", "Sorry, email username must be between 6 and 30 characters long", function (value) {
              const username = value.split("@")[0];
              return username.length >= 6 && username.length <= 30;
            })
            .test("lowercase", "Email must be lowercase", function (value) {
              return value.toLowerCase() === value;
            }),
        })}
        onSubmit={updateUserSubmit}
      >
        {({ errors, touched }) => (
          <Form className={styles.label}>
            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="fullName">
                Full Name
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="fullName"
                type="text"
                id="fullName"
                placeholder="Please enter your fullName"
              />
              {errors.fullName && touched.fullName ? (
                <span className="text-danger ms-2">{errors.fullName}</span>
              ) : null}
            </div>
            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="email">
                Email
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="email"
                type="email"
                id="email"
                placeholder="Please enter a valid email address"
              />
              {errors.email && touched.email ? (
                <span className="text-danger ms-2">{errors.email}</span>
              ) : null}
            </div>
            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="phone">
                Phone Number
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="phone"
                type="text"
                id="phone"
                placeholder="Please enter your phone number"
              />
              {errors?.phone && touched?.phone ? (
                <span className="text-danger ms-2">{errors.phone}</span>
              ) : null}
            </div>
            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="province">
                Province
              </label>
              <Field
                
                className={`form-control ${styles.input}`}
                name="address.province"
                type="text"
                id="province"
                as="select"
                value={selectedProvince}
                onChange={handleProvinceChange}
              >
                {({ field, form }) => (
                  <select
                    {...field}
                    className={`form-control ${styles.input}`}
                    id="province"
                    value={selectedProvince}
                    onChange={(e) => handleProvinceChange(e, form.setFieldValue)}
                  >
                    <option value="" id="0">
                  {selectedProvince || 'Select a Province'}
                </option>
                {provinceNames.map((province, index) => (
                  <option key={index} id={index} value={province}>
                    {province}
                  </option>
                ))}
                  </select>
                )}
               
              </Field>
              {errors.address?.province && touched?.address?.province ? (
                <div className="error">{errors.address.province}</div>
              ) : null}
              <label className="mb-1 mt-3" htmlFor="district">
                District
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="address.district"
                type="text"
                id="district"
                as="select"
                value={selectedDistrict}
                onChange={handleDistrictChange}
              >
                {({ field, form }) => (
    <select
      {...field}
      className={`form-control ${styles.input}`}
      id="district"
      value={selectedDistrict}
      onChange={(e) => handleDistrictChange(e, form.setFieldValue)}
    >
      <option value="">{selectedDistrict || "Select a District"}</option>
      {districts.map((city, index) => (
        <option key={index} value={city}>
          {city}
        </option>
      ))}
    </select>
  )}
              </Field>
              {errors.address?.district && touched?.address?.district ? (
                <div className="error">{errors.address.district}</div>
              ) : null}
            </div>
            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="municipality">
                Municipality
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="address.municipality"
                type="text"
                id="municipality"
                as="select"
                value={selectedMunicipality}
                onChange={handleMunicipalityChange}
              >
               {({ field, form }) => (
    <select
      {...field}
      className={`form-control ${styles.input}`}
      id="municipality"
      value={selectedMunicipality}
      onChange={(e) => handleMunicipalityChange(e, form.setFieldValue)}
    >
      <option value="">{selectedMunicipality || "Select a Municipality"}</option>
      {Array.isArray(municipalities) &&
        municipalities.map((municipality, index) => (
          <option key={index} value={municipality}>
            {municipality}
          </option>
        ))}
    </select>
  )}
              </Field>
              {errors.address?.municipality && touched?.address?.municipality ? (
                <span className="text-danger ms-2">
                  {errors.address.municipality}
                </span>
              ) : null}
            </div>
            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="street">
                Street
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="address.street"
                type="text"
                id="street"
                placeholder="Please enter your street address"
              />
              {errors.address?.street && touched?.address?.street ? (
                <span className="text-danger ms-2">
                  {errors.address.street}
                </span>
              ) : null}
            </div>

            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              {/* Temporary FIX */}
              <label className="mb-1" htmlFor="additionalInfo">
                Additional Information
              </label>
              <Field
                
                className={`form-control ${styles.input}`}
                name="address.additionalInfo"
                type="text"
                id="additionalInfo"
                placeholder="Enter any additional information of your address"
              />
              {errors.address?.additionalInfo && touched?.address?.additionalInfo ? (
                <span className="text-danger ms-2">
                  {errors.address.additionalInfo}
                </span>
              ) : null}
            </div>
          

            <div className={`mb-4 ${styles["max-w-xl"]}`}>
              <label className="mb-1" htmlFor="postalCode">
                Postal Code
              </label>
              <Field
                className={`form-control ${styles.input}`}
                name="address.postalCode"
                type="text"
                id="postalCode"
                placeholder="Please enter your postal Code"
              />
              {errors.address?.postalCode && touched?.address?.postalCode ? (
                <span className="text-danger ms-2">
                  {errors.address.postalCode}
                </span>
              ) : null}
            </div>
            <div className={`pt-3`}>
              {!showBtnSpinner ?
                <input
                  type="submit"
                  className={`btn-bg-dark text-center ${styles.button}`}
                  value="Update account"
                />
                :
                <button
                  type="button"
                  className={`btn-bg-dark text-center ${styles.button}`}
                >
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </button>

              }
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountInfo;
