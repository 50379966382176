import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import React from "react";
// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// style
import  styles  from "./../../pages/home/home.module.css"


import axiosInstance from "../../apis/config";

const MainCarousel = () => {

      const[carouselImage, setCarouselImage] = React.useState();

      React.useEffect(()=>{
        console.log("Carousel Image");
        const getCarouselImage = async()=>{
          try{
            const res = await axiosInstance.get('/products/carousel');
            setCarouselImage(res.data);
          }catch(error){
            console.log(error);
          }
        }
        getCarouselImage();
      },[])

    return (
    <> 

    <Carousel fade className={styles["carousel-indicators"]}>
      {carouselImage && carouselImage.map((img) => (
        <Carousel.Item key={img._id} className={`${styles["carousel-item"]}`} >
        <img 
      
        className={`d-block w-100 h-[100px] ${styles.carouselImage}`}
         src={`${img.images[0].src}`} alt={img.name}
        />
          <Carousel.Caption className={`${styles["carousel-caption"]}`}>
            <div className={`${styles.captionCarouel}` }>
            <div className="row">
            <p className={`col-5 col-md-3 col-lg-2  ${styles.discount}`}>Up to {img.discount}% OFF</p>
            </div>
            <div className="row">
            <h3 className="col-10 col-md-6 col-xl-8"
            
            >{img.name}</h3>
            </div>
            <Link to="/shop" className="text-decoration-none cursor-pointer">
             <p className={styles.explore}> Explore Now  <span className="ms-2 "><FontAwesomeIcon icon={faArrowRight}/></span></p>
            </Link>
             </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
    </>  
    
    );
}
 
export default MainCarousel;