import styles from "../../pages/about/about.module.css";

export default function WhatTell() {
  return (
    <div className={`${styles.thecontent} container`}>
      <h1 className={`pt-5`}>What They're Saying</h1>
      <p className={`pb-3 pt-5`}>
        "I recently purchased a t-shirt from Durmade, and I couldn't be happier
        with my experience. The process of ordering online was so easy and
        stress-free, and the customer service team was incredibly helpful when
        I had some questions about delivery. When the t-shirt arrived, I was
        blown away by the quality. The fabric was so comfortable and durable,
        and the fit was perfect. It's now my go-to t-shirt for any occasion. I
        highly recommend Durmade to anyone looking for quality clothing at
        affordable prices."
      </p>
      <h5>Shaswat Pant</h5>
      <div>
        <h6 className="mb-4">Analyst</h6>
      </div>
    </div>
  );
}
