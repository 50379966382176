import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import jwtDecode from "jwt-decode";

import axiosInstance from "../../apis/config";
import { nep_cities } from "../../apis/nepali_cities";

//component
import Spinner from "../common/spinner";
//style
import style from "../../pages/checkout/checkout.module.css";

// Helper functions
const getProvinceNames = (cities) => Object.keys(cities);
const getDistrictsByProvince = (province) => province && nep_cities[province] ? Object.keys(nep_cities[province]) : [];
const getMunicipalities = (province, district) => province && district && nep_cities[province] && nep_cities[province][district]
  ? Object.values(nep_cities[province][district]).flat()
  : [];

// validation
const DisplayingErrorMessagesSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full name is required")
    .matches(/^[a-zA-Z ]+$/, "Full name shouldn't have numbers")
    .min(3, "Full name must be at least 3 characters")
    .max(50, "Full name must be less than 50 characters"),
  phone: Yup.string()
    .required("Required")
    .matches(
      /^[9][6-8]\d{8}$/,
      "Invalid phone number. Must be a Nepali phone number"
    ),
  address: Yup.object({
    province: Yup.string().required("Required").label("Province"),
    district: Yup.string().required("Required").label("District"),
    municipality: Yup.string().required("Required").label("Municipality"),
    street: Yup.string().label("Street").required("Required"),
    additionalInfo: Yup.string().label("Additional Info"),
    postalCode: Yup.string()
      .required("Required")
      .matches(/^\d{5}$/, "Postal code must be exactly 5 digits"),
  }),
});

export default function FormComponent() {
  const [saveInfo, setSaveInfo] = useState(true);
  const [user, setUser] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districts, setDistricts] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);

  const navigate = useNavigate();
  const cart = useSelector(state => state.cart.cart);

  const token = localStorage.getItem("userToken");
  const decoded = jwtDecode(token);

  const savedFormData = localStorage.getItem("localFormData");

  //initial value
  const [theInitialValue, setTheInitialValue] = useState(() => {
    if (savedFormData) {
      return JSON.parse(savedFormData);
    } else {
      return {
        fullName: user.fullName || "",
        phone: user?.phone || "",
        address: {
          province: user?.address?.province || "",
          district: user?.address?.district || "",
          municipality: user?.address?.municipality || "",
          street: user?.address?.street || "",
          additionalInfo: user?.address?.additionalInfo || "",
          postalCode: user?.address?.postalCode || "",
        },
      };
    }
  });

  useEffect(() => {
    axiosInstance
      .get(`/users/${decoded.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      })
      .then(res => {
        setUser(res.data);
        if (savedFormData) {
          setTheInitialValue(JSON.parse(savedFormData));
        } else {
          setTheInitialValue(res.data);
        }
        setSelectedProvince(res.data.address?.province || "");
        setSelectedDistrict(res.data.address?.district || "");
      })
      .catch(err => console.log(err));
  }, [decoded.id, token]);

  useEffect(() => {
    setDistricts(getDistrictsByProvince(selectedProvince));
    if (selectedDistrict) {
      setMunicipalities(getMunicipalities(selectedProvince, selectedDistrict));
    }
  }, [selectedProvince, selectedDistrict]);

  const formSubmit = submitdata => {
    navigate(`/checkout/shipping`);


    let theSendData = {
      id: decoded.id,
      phone: submitdata?.phone,
      address: {
        province: submitdata?.address?.province,
        district: submitdata?.address?.district,
        municipality: submitdata?.address?.municipality,
        street: submitdata?.address?.street,
        additionalInfo: submitdata?.address?.additionalInfo,
        postalCode: submitdata?.address?.postalCode,
      },
    };

    localStorage.setItem("localFormData", JSON.stringify(submitdata));

    if (saveInfo) {
      axiosInstance
        .patch("/users", theSendData, {
          params: { id: decoded.id },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        })
        .then(res => {})
        .catch(err => console.log(err));
    }
  };

  if (!user) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const provinceNames = getProvinceNames(nep_cities);

  return (
    <div className="p-4">
      <Formik
        initialValues={theInitialValue}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={formSubmit}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <h6> Contact </h6>
            <div className="form-group form-floating ">
              <Field
                name="phone"
                placeholder="phone"
                className="form-control"
                type="text"
                id="phone"
              />
              <label htmlFor="phone">Phone </label>
              {touched.phone && errors.phone && (
                <div className="text-danger ms-2">{errors.phone}</div>
              )}
            </div>
            <h6 className={`mb-0 mt-4 `}> Shipping address </h6>

            <div className="form-group form-floating ">
              <Field
                name="fullName"
                placeholder="full name"
                className="form-control"
                type="text"
                id="fullName"
              />
              <label htmlFor="fullName">Full Name </label>
              {touched.fullName && errors.fullName && (
                <div className="text-danger ms-2">{errors.fullName}</div>
              )}
            </div>

            <div className="form-group form-floating ">
              <Field name="address.province">
                {({ field }) => (
                  <select
                    {...field}
                    className="form-control"
                    id="province"
                    onChange={(e) => {
                      const province = e.target.value;
                      setFieldValue('address.province', province);
                      setFieldValue('address.district', '');
                      setFieldValue('address.municipality', '');
                      setSelectedProvince(province);
                      setSelectedDistrict('');
                    }}
                  >
                    <option value="">Select a Province</option>
                    {provinceNames.map((province, index) => (
                      <option key={index} value={province}>
                        {province}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
              <label htmlFor="province">Province </label>
              {touched.address?.province && errors.address?.province && (
                <div className="text-danger ms-2">{errors.address?.province}</div>
              )}
            </div>

            <div className="form-group form-floating ">
              <Field name="address.district">
                {({ field }) => (
                  <select
                    {...field}
                    className="form-control"
                    id="district"
                    onChange={(e) => {
                      const district = e.target.value;
                      setFieldValue('address.district', district);
                      setFieldValue('address.municipality', '');
                      setSelectedDistrict(district);
                    }}
                  >
                    <option value="">Select a District</option>
                    {districts.map((district, index) => (
                      <option key={index} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
              <label htmlFor="district">District </label>
              {touched.address?.district && errors.address?.district && (
                <div className="text-danger ms-2">{errors.address?.district}</div>
              )}
            </div>

            <div className="form-group form-floating ">
              <Field name="address.municipality">
                {({ field }) => (
                  <select
                    {...field}
                    className="form-control"
                    id="municipality"
                  >
                    <option value="">Select a Municipality</option>
                    {municipalities.map((municipality, index) => (
                      <option key={index} value={municipality}>
                        {municipality}
                      </option>
                    ))}
                  </select>
                )}
              </Field>
              <label htmlFor="municipality">Municipality </label>
              {touched.address?.municipality && errors.address?.municipality && (
                <div className="text-danger ms-2">{errors.address?.municipality}</div>
              )}
            </div>

            <div className={`${style.formGroup} form-group form-floating `}>
              <Field
                name="address.street"
                placeholder="Street"
                className="form-control"
                type="text"
                id="street"
              />
              <label htmlFor="street">Street </label>
              {touched.address?.street && errors.address?.street && (
                <div className="text-danger ms-2">{errors.address?.street}</div>
              )}
            </div>

            <div className={`${style.formGroup} form-group form-floating `}>
              <Field
                name="address.additionalInfo"
                placeholder="Additional Info"
                className="form-control"
                type="text"
                id="additionalInfo"
              />
              <label htmlFor="additionalInfo">Additional Info </label>
              {touched.address?.additionalInfo && errors.address?.additionalInfo && (
                <div className="text-danger ms-2">{errors.address?.additionalInfo}</div>
              )}
            </div>

            <div className="form-group form-floating ">
              <Field
                name="address.postalCode"
                placeholder="Postal Code"
                className="form-control"
                type="text"
                id="postalCode"
              />
              <label htmlFor="postalCode">Postal Code </label>
              {touched.address?.postalCode && errors.address?.postalCode && (
                <div className="text-danger ms-2">{errors.address?.postalCode}</div>
              )}
            </div>

            <div className="form-check my-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={saveInfo}
                onChange={e => setSaveInfo(e.target.checked)}
              />
              <label
                className={`${style.checklabal} form-check-label mt-2`}
                htmlFor="exampleCheck1"
              >
                Save this information for next time
              </label>
            </div>

            <div className="row mb-4 w-100 m-auto">
              <Link
                className={`col-lg-6 col-md-6 col-sm-12 col-12 mt-2 mb-3 mt-4 ${style.returnLink} text-decoration-none`}
                to="/cart"
              >
                {`<  `} Return to Cart
              </Link>
              <button
                type="submit"
                disabled={cart?.totalPrice === 0}
                className={`${style.formbtn} col-lg-6 col-md-6 col-sm-12 col-12 btn h-100 ws-100 me-0`}
              >
                Continue to Shipping
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}